import { isToken } from 'typescript'
import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'REVO-BNB LP',
    lpAddresses: {
      97: '0xa9B030d6961d26235Bc0fEFe79311Db2cBbDe154',
      56: '0xEBD43eFB20Fa13686DbEA45c29824f8b421AA3fb',
      80001: '0x201D68aD8a37C2a78aA3FeF85c43368341481b13',
    },
    tokenSymbol: 'REVO',
    tokenAddresses: {
      97: '0x93dFbB3E3bC4b4b10574d908FE9598d38AA764e6',
      56: '0x678e5f70b6b582dfadb3dbd68af17801d34555c5',
      80001: '0x201D68aD8a37C2a78aA3FeF85c43368341481b13',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,

    lpSymbol: 'REVO',
    lpAddresses: {
      97: '0x8A5F01662D3bD36bb25f0449559301856396a001',
      56: '0xEBD43eFB20Fa13686DbEA45c29824f8b421AA3fb',
      80001: '0x572b1AcA5984A5Cd9aEB575a446E18c69418a727',
    },
    tokenSymbol: 'REVO',
    tokenAddresses: {
      97: '0x8A5F01662D3bD36bb25f0449559301856396a001',
      56: '0x678e5f70b6b582dfadb3dbd68af17801d34555c5',
      80001: '0x201D68aD8a37C2a78aA3FeF85c43368341481b13',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 2,
    risk: 5,

    lpSymbol: 'REVO-BUSD',
    lpAddresses: {
      97: '0x8A5F01662D3bD36bb25f0449559301856396a001',
      56: '0x4f4BC5814b0DD1346A99BcC25a43bdFb74884c3d',
      80001: '0x572b1AcA5984A5Cd9aEB575a446E18c69418a727',
    },
    tokenSymbol: 'REVO',
    tokenAddresses: {
      97: '0x8A5F01662D3bD36bb25f0449559301856396a001',
      56: '0x678e5f70b6b582dfadb3dbd68af17801d34555c5',
      80001: '0x201D68aD8a37C2a78aA3FeF85c43368341481b13',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 3,
    risk: 5,

    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '0x8A5F01662D3bD36bb25f0449559301856396a001',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      80001: '0x572b1AcA5984A5Cd9aEB575a446E18c69418a727',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x8A5F01662D3bD36bb25f0449559301856396a001',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      80001: '0x201D68aD8a37C2a78aA3FeF85c43368341481b13',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



 

 


 

 

  // {
  //   pid: 3,
  //   risk:5,
  //   lpSymbol: 'ETH-BNB LP',
  //   lpAddresses: {
  //     97: '0x5151D1Fd6aCF781144175383C65744Db0c5A28a9',
  //     56: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '0xd0b4c1b5d59aa41e522699b6e469704916faed95',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
]

export default farms
