export default {
  cake: {
    97: '0xa9B030d6961d26235Bc0fEFe79311Db2cBbDe154',
    56: '0x678e5f70b6b582dfadb3dbd68af17801d34555c5',
    80001:'0x201D68aD8a37C2a78aA3FeF85c43368341481b13',
  },
  // syrup: {
  //   97: '0x6e6059CdcF38252B9ad5DC743e7ea27B4E8a3e49',
  //   56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  // },
  masterChef: {
    97: '0x98dE1E5A8bAFc1F3Cf94BF80755F3009e58db1fa',
    56: '0x049ab55517e6439e2Fd5CA6888f46398922bDa1C',
    80001:'0xc853FF11Ac73dE77f540e1247f09cD9e96dE3924',

  },
  // sousChef: {
  //   97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //   56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  // },
  wbnb: {
    97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    80001:'0x0FD23FA2AADC9BA7A31aA0d948ceCCE74f827203',
  },
  lottery: {
    97: '0x63744802C53534E7aa39a830D9008ACF4e32517F',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
    80001: '0x63744802C53534E7aa39a830D9008ACF4e32517F',
    
  },
  lotteryNFT: {
    97: '0xfcd4C2988b575Cd8e9E03127C02A58333f73d4d4',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
    80001: '0xfcd4C2988b575Cd8e9E03127C02A58333f73d4d4',

  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    80001:'0x06A0575A64593c1dD497020bC80C8c4663BeaF4D',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    80001:'0x06A0575A64593c1dD497020bC80C8c4663BeaF4D',

    97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
  },
  // ust: {
  //   56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  //   97: '',
  // },
  // pancakeProfile: {
  //   56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
  //   97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  // },
  // pancakeRabbits: {
  //   56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
  //   97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  // },
  // bunnyFactory: {
  //   56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
  //   97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  // },
  // eth: {
  //   56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   97: '0xd0b4c1b5d59aa41e522699b6e469704916faed95',
  // },
  // claimRefund: {
  //   56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
  //   97: '',
  // },
  // pointCenterIfo: {
  //   56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
  //   97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  // },
  // bunnySpecial: {
  //   56: '0xFee8A195570a18461146F401d6033f5ab3380849',
  //   97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  // },
}
